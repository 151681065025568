<template>
  <div id="theWebSearchUserLoginDiv" class="vld-parent">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />    
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'TheWebSearchUserLogin',
  components: {
    Loading,
  },
  data: function () {
    return {
      isLoading: true,
      loaderBackgroundColor,
      loaderColor,
    }
  },
  computed: {  
    isSignedIn() { return this.$store.state.common.isSignedIn },
  },
  async beforeCreate() {
    if (this.isSignedIn) {
      await this.$store.dispatch('common/signOut')
    }
  },
  async created() {
    await this.attemptAuth()
    this.initialLoad = false
  },
  methods: {
    async attemptAuth() {
      this.isLoading = true

      const payload = {
        username: this.$route.params.webSearchUserName,
        password: 'pw',
      }

      const loginResult = await this.$store.dispatch('security/authenticateWebSearchLogin', payload)
      let redirectName = 'TheLogin'
      if (loginResult && loginResult.Token) {
        await this.$store.dispatch('mainViews/toggleVasionHome', true)
        redirectName = 'TheVasionHome'
      }

      await this.$router.push({ name: redirectName })
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
</style>
